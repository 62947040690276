import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SubscribePage from "./SubscribePage";
import { ThemeProvider } from "@mui/system";
import { theme } from "./lib/theme/theme";

let stripeKey = "pk_test_fffT4kgpcnJ516AiG3yWnLnS";
if (window.location.hostname === "subscribe.easybusinessapp.com") {
  stripeKey = "pk_live_a2e6WczzNfS653VzcIjTioWA";
}

const stripePromise = loadStripe(stripeKey);

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <SubscribePage />
      </Elements>
    </ThemeProvider>
  );
}
