import { Box, Button, Card, Typography, useTheme } from "@mui/material";
import { default as Grid } from "@mui/material/Unstable_Grid2"; // Grid version 2
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";
import { FC } from "react";
import { SubscriptionPlan } from "../lib/types/all";
import { generateCouponString } from "../lib/util/helper";

export interface ISubscriptionCard {
  plan: SubscriptionPlan;
  openModal: (plan: SubscriptionPlan) => void;
}

export const SubscriptionCard: FC<ISubscriptionCard> = ({
  plan,
  openModal,
}) => {
  const theme = useTheme();
  return (
    <Card sx={{ px: 2, py: 4, m: 1 }}>
      <Typography
        variant={"subtitle1"}
        align="center"
        color={"text.light"}
        py={0.5}
      >
        {plan.planName}
      </Typography>
      {plan.finalAmount !== plan.amount && (
        <Typography
          color={"grey"}
          style={{
            textDecorationLine: "line-through",
            textDecorationStyle: "solid",
            fontSize: 14,
            textAlign: "center",
          }}
        >
          ${plan.amount}
        </Typography>
      )}
      <Typography variant="h4" align="center" py={0.5} fontWeight={700}>
        ${plan.finalAmount}/m
      </Typography>
      {plan.coupon && (
        <Typography
          color={"green"}
          style={{
            fontSize: 14,
            textAlign: "center",
            fontStyle: "italic",
            marginTop: 4,
          }}
        >
          {generateCouponString(plan.coupon)}
        </Typography>
      )}
      <Grid container justifyContent={"center"} alignContent={"center"}>
        <Box p={2}>
          <>
            {plan.features.map((planItem) => {
              return (
                <Grid
                  key={planItem}
                  container
                  alignContent="center"
                  flexWrap={"nowrap"}
                >
                  <CheckCircleIcon htmlColor={theme.payroll.main} />
                  <Typography pl={1}>{planItem}</Typography>
                </Grid>
              );
            })}
          </>
        </Box>
      </Grid>
      <Grid container justifyContent={"center"} alignContent={"center"}>
        <Button
          size={"large"}
          variant={"contained"}
          onClick={() => openModal(plan)}
        >
          Subscribe Now
        </Button>
      </Grid>
    </Card>
  );
};
